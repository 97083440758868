<template>
  <div>
    <h2 class="mb-2">Hola {{ name }}</h2>
    <p v-if="text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
};
</script>
