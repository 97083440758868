<template>
  <b-row id="client-manual-notification-language-tab" no-gutters>
    <b-col cols="12" class="border-bottom">
      <h5 class="font-weight-bold p-2 mb-0 text-center bg-light">
        {{ subject }}
      </h5>
    </b-col>
    <b-col cols="12" class="p-2">
      <intro class="mb-3" :name="client.firstName" />
      <quill-editor
        :ref="`textEditor${locale}`"
        v-model="text"
        :options="editorOptions"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import Intro from "@/views/bookings/booking/components/mail/Intro.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BRow,
    BCol,
    Intro,
    quillEditor,
  },
  props: {
    locale: {
      type: String,
      default: "en-GB",
    },
  },
  data() {
    return {
      content: {},
      text: "",
      editorOptions: {
        theme: "bubble",
        placeholder: this.$t(
          "emails.clientManualNotification.writeMessageHere",
          this.locale
        ),
        modules: { toolbar: false },
      },
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    accommodationName() {
      return this.accommodation?.name || null;
    },
    subject() {
      return `${this.$t(
        "emails.clientManualNotification.subject",
        this.locale,
        {
          localizator: this.booking.localizator,
          accommodationName: this.accommodationName,
        }
      )}`;
    },
  },
  watch: {
    text(text) {
      this.content.text = text;
    },
    content: {
      deep: true,
      handler() {
        this.$emit("content-updated", this.content);
      },
    },
  },
  created() {
    this.initContent();
  },
  methods: {
    initContent() {
      this.content = {
        locale: this.locale,
        subject: this.subject,
        clientName: this.client?.firstName || null,
        clientEmail: this.client?.email || null,
      };
    },
  },
};
</script>

<style lang="scss">
#client-manual-notification-language-tab {
  .quill-editor {
    .ql-editor {
      padding: 0;
      p {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
